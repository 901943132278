import { Link } from '@remix-run/react'

import { createEventOrdersPath } from '../../../features/tickets/ticketRoutes'
import { EventDetailsFragment } from '../../../graphql/fragments/eventDetails.fragment.gen'
import { primary } from '../../Button/Button.styles'

interface OrdersButtonProps {
  event: EventDetailsFragment
  style?: Style
}

export const EventOrdersButton: React.FC<OrdersButtonProps> = ({
  event,
  style = primary({ fullWidth: true }),
}) => {
  return (
    <Link css={style} to={createEventOrdersPath(event.slug)} tabIndex={0}>
      <i className="fa fa-inbox" />
      Orders
    </Link>
  )
}
